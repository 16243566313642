import React from 'react';
import { graphql } from 'gatsby';
import { Position } from '../sdk/entities/Position';
import { SEO } from '../components/seo/seo';
import { Layout } from '../components/layout/layout';
import { Vacancy } from '../sdk/entities/Vacancy';
import VacanciesList from '../components/vacancies-list/vacancies-list';
import DynamicContent from '../components/dynamic-content/dynamic-content';
import NoVacancyAdvice from '../components/no-vacancy-advice/no-vacancy-advice';
import VacancyFilterContainer from '../components/vacancy-filter-container/vacancy-filter-container';

export type PositionPageProps = {
  data: {
    position: Position & { text: string };
    allVacancy: {
      nodes: Vacancy[];
    };
    workFieldVacancies: {
      nodes: Vacancy[];
    };
  };
};

const PositionPage: React.FC<PositionPageProps> = ({ data, ...rest }) => {
  const hasVacancies = data.allVacancy.nodes.length > 0;

  return (
    <main>
      <Layout
        breadcrumbItems={[
          {
            title: data.position.workField?.name!,
            href: `/${data.position.workField?.slug}/`,
          },
          {
            title: data.position.name!,
            href: `/${data.position.workField?.slug}/${data.position.slug}/`,
          },
        ]}
        hideFilter={true}
        hideSearch
      >
        <h1 className="text-[32px]">Vagas para {data.position.name}</h1>

        <div className="my-16">
          <VacancyFilterContainer
            fullWidth
            filters={{
              positionId: data.position.id,
              workFieldId: data.position.workField?.id,
            }}
          />
        </div>

        {!hasVacancies && (
          <div className="my-16">
            <NoVacancyAdvice
              buttonText={`Ver mais vagas de ${data.position.workField?.name}`}
              buttonUrl={`/${data.position.workField?.slug}/`}
              positionName={data.position.name}
              workFieldName={data.position.workField?.name}
            />
          </div>
        )}

        {data.allVacancy.nodes.length > 0 && (
          <div className="my-16">
            <VacanciesList
              title={`Vagas para ${data.position.name}`}
              vacancies={data.allVacancy.nodes}
              queryParams={{
                positionId: data.position.id,
                workFieldId: data.position.workField?.id,
                workFieldIdName: data.position.workField?.name,
                positionIdName: data.position.name,
              }}
            />
          </div>
        )}

        {data.allVacancy.nodes.length === 0 &&
          data.workFieldVacancies.nodes.length > 0 && (
            <div className="my-16">
              <VacanciesList
                title={`Vagas Recomendadas`}
                vacancies={data.workFieldVacancies.nodes}
              />
            </div>
          )}

        <div className="my-16">
          <DynamicContent text={data.position.text} />
        </div>
      </Layout>
    </main>
  );
};

export const query = graphql`
  query ($id: String!, $workFieldSlug: String) {
    position(_id: { eq: $id }) {
      id
      name
      slug
      text
      workField {
        name
        slug
        id
      }
    }
    allVacancy(
      sort: { createdAt: DESC }
      filter: { active: { eq: true }, positionId: { eq: $id } }
      limit: 5
    ) {
      nodes {
        id
        _id
        name
        maxRemuneration
        confidential
        contractModel
        description
        remuneration
        remunerationModel
        slug
        position {
          slug
          workField {
            slug
          }
        }
        workplace {
          name
          address {
            city
            complement
            country
            district
            lat
            lng
            number
            postalCode
            referencePoint
            state
            street
          }
          image
        }
        officeDays
        image {
          childImageSharp {
            gatsbyImageData(
              width: 40
              height: 40
              transformOptions: { fit: COVER }
              quality: 80
            )
          }
        }
      }
    }
    workFieldVacancies: allVacancy(
      sort: { createdAt: DESC }
      limit: 5
      filter: {
        active: { eq: true }
        position: { workField: { slug: { eq: $workFieldSlug } } }
      }
    ) {
      nodes {
        id
        _id
        name
        maxRemuneration
        confidential
        contractModel
        description
        remuneration
        remunerationModel
        slug
        position {
          slug
          workField {
            slug
          }
        }
        workplace {
          name
          address {
            city
            complement
            country
            district
            lat
            lng
            number
            postalCode
            referencePoint
            state
            street
          }
          image
        }
        officeDays
        image {
          childImageSharp {
            gatsbyImageData(
              width: 40
              height: 40
              transformOptions: { fit: COVER }
              quality: 80
            )
          }
        }
      }
    }
  }
`;

export const Head: React.FC<PositionPageProps> = ({ data }) => (
  <SEO
    title={`Vagas para ${data.position.name}`}
    description={`Veja as vagas disponíveis para ${data.position.name}`}
    pathname={`/${data.position.workField?.slug}/${data.position.slug}/`}
    breadcrumbItems={[
      {
        title: data.position.workField?.name!,
        href: `/${data.position.workField?.slug}/`,
      },
      {
        title: data.position.name!,
        href: `/${data.position.workField?.slug}/${data.position.slug}/`,
      },
    ]}
  />
);

export default PositionPage;
